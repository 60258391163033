/* eslint-disable import/named */
import * as React from "react"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"

type DataProps = {
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const Info: React.FC<DataProps> = ({ body }) => {
  return (
    <div className="columns is-centered container">
      <div className="info-body column is-two-thirds-tablet is-one-half-widescreen">
        {body && renderRichText(body)}
      </div>
    </div>
  )
}

export default Info
