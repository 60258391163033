import React, { Component } from "react"

type DataProps = {
  date: string
}

type DataState = {
  days: number
  hours: number
  min: number
  sec: number
}

// https://github.com/kristinbaumann/react-countdown
class Countdown extends Component<DataProps, DataState> {
  interval: NodeJS.Timeout

  public static defaultProps = {
    date: new Date(),
  }

  constructor(props: { date: string }) {
    super(props)

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }

    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date)
      if (date) this.setState(date)
      else {
        this.stop()
        window.location.reload()
      }
    }, 1000)
  }

  componentWillUnmount() {
    this.stop()
  }

  calculateCountdown(endDate: string) {
    let diff =
      (Date.parse(new Date(endDate).toString()) -
        Date.parse(new Date().toString())) /
      1000

    // clear countdown when date is reached
    if (diff <= 0) return false

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400))
      diff -= timeLeft.years * 365.25 * 86400
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400)
      diff -= timeLeft.days * 86400
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600)
      diff -= timeLeft.hours * 3600
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60)
      diff -= timeLeft.min * 60
    }
    timeLeft.sec = diff

    return timeLeft
  }

  stop() {
    clearInterval(this.interval)
  }

  addLeadingZeros(value: string) {
    value = String(value)
    while (value.length < 2) {
      value = "0" + value
    }
    return value
  }

  render() {
    return (
      <span className="Countdown">
        {this.addLeadingZeros(String(this.state.days))}:
        {this.addLeadingZeros(String(this.state.hours))}:
        {this.addLeadingZeros(String(this.state.min))}:
        {this.addLeadingZeros(String(this.state.sec))}
      </span>
    )
  }
}

export default Countdown
