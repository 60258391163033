import * as React from "react"
import { Link } from "gatsby"
import { Menu, MenuButton, MenuGroup, MenuItem } from "@szhsin/react-menu"
import ReactTooltip from "react-tooltip"

import { Countdown } from "./"

import Discord from "../../static/discord.svg"
import Paypal from "../../static/paypal.svg"
import InfoMono from "../../static/info-mono.svg"
import Twitter from "../../static/twitter.svg"
import Ellipsis from "../../static/ellipsis.svg"

type DataProps = {
  root?: boolean
  expires?: Date
  isReset?: boolean
  isRoot?: boolean
}

const Header: React.FC<DataProps> = ({ root, expires, isReset, isRoot }) => {
  // https://codesandbox.io/s/brave-rosalind-4bb4v?file=/src/App.js
  const [isTooltipVisible, setTooltipVisibility] = React.useState(false)

  React.useEffect(() => {
    setTooltipVisibility(true)
  }, [])

  if (!expires) expires = new Date()

  return (
    <header>
      {root && <div className="navbar__background" />}
      <nav className="navbar" aria-label="main navigation">
        <div className="navbar__left">
          {!isRoot ? (
            <Link to="/">Where is Maurice?</Link>
          ) : isReset ? (
            <p>Resets Today!</p>
          ) : (
            <>
              <Countdown date={expires.toISOString()} />
              <div data-tip={true} data-for="countdown" className="info">
                <InfoMono aria-hidden="true" />
                <span className="visible-hidden">Countdown until reset.</span>
              </div>
              {isTooltipVisible && (
                <ReactTooltip
                  id="countdown"
                  className="navbar__tooltip"
                  place="right"
                  type="dark"
                  effect="solid"
                  insecure={true}
                  backgroundColor="#019ef9"
                >
                  Countdown until reset.
                </ReactTooltip>
              )}
            </>
          )}
        </div>
        <div className="navbar__right">
          <a
            href="https://discord.gg/82Rbh4K5w6"
            rel="noopener noreferrer"
            target="_blank"
            className="navbar__item"
          >
            <Discord aria-hidden="true" />
            <span className="visible-hidden">Discord</span>
          </a>
          <a
            href="https://twitter.com/wheremaurice"
            rel="noopener noreferrer"
            target="_blank"
            className="navbar__item"
          >
            <Twitter aria-hidden="true" />
            <span className="visible-hidden">Twitter</span>
          </a>
          <a
            href="https://www.paypal.me/ceiphr"
            rel="noopener noreferrer"
            target="_blank"
            className="navbar__item"
          >
            <Paypal aria-hidden="true" />
            <span className="visible-hidden">Paypal</span>
          </a>
          <label htmlFor="more" className="is-hidden">
            More Links
          </label>
          <Menu
            menuButton={
              <MenuButton id="more" className="navbar__item" aria-label="More">
                <Ellipsis />
              </MenuButton>
            }
          >
            <MenuGroup>
              <MenuItem>
                <Link to="/about/">About</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/disclaimers/">Disclaimers</Link>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://www.iubenda.com/privacy-policy/74492848"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy
                </a>
              </MenuItem>
              <MenuItem>
                <a href="/rss">RSS</a>
              </MenuItem>
            </MenuGroup>
          </Menu>
        </div>
      </nav>
    </header>
  )
}

export default Header
