/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

type DataProps = {
  children: any
  isRoot?: boolean
}

const Layout: React.FC<DataProps> = ({ children, isRoot }) => {
  return <main id={isRoot ? "root" : ""}>{children}</main>
}

export default Layout
