/* eslint-disable import/named */
import React from "react"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import ReactTooltip from "react-tooltip"

import External from "../../static/external.svg"
import InfoMono from "../../static/info-mono.svg"

type DataProps = {
  readonly data: {
    price: string
    itemScore: string
    levelRequired: string
    title: string
    image: {
      gatsbyImageData: GatsbyImageProps
    }
    stats: Array<{
      id: string
      type: string
      value: string
    }>
    attributes: RenderRichTextData<ContentfulRichTextGatsbyReference>
    anointed: RenderRichTextData<ContentfulRichTextGatsbyReference>
    brand: string
    type: string
    link: string
  }
}

/**
 * @param data.price    Information about the object.
 */
const ItemCard: React.FC<DataProps> = ({ data }) => {
  const price = Number(data.price).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
  const {
    itemScore,
    levelRequired,
    title,
    image,
    stats,
    attributes,
    anointed,
    brand,
    type,
    link,
  } = data

  return (
    <div className="card">
      <div className="card__item-score">{itemScore}</div>
      <div className="card__level">Lv. {levelRequired}</div>
      <div className="card__title">
        {link ? (
          <a href={link} rel="noopener noreferrer" target="_blank">
            {title}
            <External />
          </a>
        ) : (
          { title }
        )}
      </div>
      <div className="card__inner">
        <GatsbyImage
          className="card__image"
          layout="fullWidth"
          backgroundColor="rgb(21, 24, 29)"
          // @ts-ignore
          image={image.gatsbyImageData}
          alt={title}
        />
        <div className="card__body">
          <table className="table">
            <tbody>
              {stats.map(item => {
                return (
                  <tr key={item.id}>
                    <th>{item.type}</th>
                    <td>{item.value}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="card__attributes">{renderRichText(attributes)}</div>
          <div className="card__anoints">
            <div className="info" data-tip={true} data-for="anointed">
              <InfoMono aria-hidden="true" />
              <span className="visible-hidden">Anointed only.</span>
            </div>
            <ReactTooltip
              id="anointed"
              className="tooltip"
              place="left"
              type="dark"
              effect="solid"
              backgroundColor="rgb(161, 109, 7)"
            >
              Anointed only.
            </ReactTooltip>
            {renderRichText(anointed)}
          </div>
        </div>
      </div>
      <div className="card__footer">
        <p className="card__price">{price.substring(0, price.length - 3)}</p>
        <p className="card__tag">
          {brand}
          {", "}
          {type}
        </p>
      </div>
    </div>
  )
}

export default ItemCard
