import React, { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import { ImageOverlay, MapContainer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import { isIOS, isSafari } from "react-device-detect"
import "leaflet/dist/leaflet.css"

import Legendary from "../../static/legendary.svg"

type DataProps = {
  readonly location?: {
    latitude: number
    longitude: number
    screenshot: {
      gatsbyImageData: GatsbyImageProps
    }
    map: string
    mapTile: {
      file: {
        url: string
        details: {
          image: {
            height: number
            width: number
          }
        }
      }
    }
  }
}

/**
 * @param location.attribution  Credit for map asset.
 * @param location.mapTile      Map asset.
 * @param location.screenshot   Screenshot used in marker popup.
 * @param location.map       Name of the map.
 * @param location.latitude     Latitude of the marker.
 * @param location.longitude    Longitude of the marker.
 */
const BLMap: React.FC<DataProps> = ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulWeek(sort: { fields: date, order: DESC }, limit: 1) {
          edges {
            node {
              location {
                map
                planet
                latitude
                longitude
                mapTile {
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                }
                screenshot {
                  gatsbyImageData(
                    height: 196
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  if (typeof location === "undefined")
    location = query.allContentfulWeek.edges[0].node.location

  const [safari, setSafari] = useState<Boolean>()

  useEffect(() => {
    setSafari(isSafari)
  }, [setSafari])

  const [IOS, setIOS] = useState<Boolean>()

  useEffect(() => {
    setIOS(isIOS)
  }, [setIOS])

  const [animated = true, setAnimation] = useState<Boolean>()

  if (typeof window === "undefined") return <></>

  const bounds = [
    [0, 0],
    [
      location!.mapTile.file.details.image.height,
      location!.mapTile.file.details.image.width,
    ],
  ]

  const position = [
    location!.mapTile.file.details.image.height - location!.latitude,
    location!.longitude,
  ]

  const icon = new L.DivIcon({
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -10],
    className: `marker ${!safari && !IOS && `animated-marker`} ${
      !animated && `animated-marker--hidden`
    }`,
    html: ReactDOMServer.renderToString(<Legendary />),
  })
  const attributes =
    '<div class="leaflet-attribute"><p>Map © 2022 <a href="https://www.lootlemon.com/">Lootlemon</a>.</p></div>'

  return (
    <MapContainer
      center={{ lat: position[0], lng: position[1] }}
      crs={L.CRS.Simple}
      minZoom={-2}
      zoom={-1}
      maxZoom={0}
      // @ts-ignore
      bounds={bounds}
      zoomControl={false}
    >
      <Marker
        // @ts-ignore
        position={position}
        icon={icon}
        eventHandlers={{
          click: () => {
            setAnimation(false)
          },
        }}
      >
        {!(safari || IOS) && (
          <Popup>
            <div className="leaflet-popup-content-inner">
              <GatsbyImage
                // @ts-ignore
                image={location.screenshot.gatsbyImageData}
                alt={location!.map + " Vending Machine Screenshot"}
              />
            </div>
          </Popup>
        )}
      </Marker>
      <ImageOverlay
        attribution={attributes}
        // @ts-ignore
        bounds={bounds}
        url={location!.mapTile.file.url}
      />
    </MapContainer>
  )
}

export default BLMap
