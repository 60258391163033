/* eslint-disable */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImageProps } from "gatsby-plugin-image"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"

import { ItemCard } from "./"

type DataProps = {
  readonly items?: Array<{
    image: {
      id: string
      gatsbyImageData: GatsbyImageProps
      title: string
      itemScore: number
      levelRequired: number
      type: string
      stats: Array<{
        id: string
        type: string
        value: string
      }>
      attributes: RenderRichTextData<ContentfulRichTextGatsbyReference>
      anointed: RenderRichTextData<ContentfulRichTextGatsbyReference>
      price: number
      brand: string
      link: string
    }
  }>
}

const ItemRow: React.FC<DataProps> = ({ items }) => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulWeek(sort: { fields: date, order: DESC }, limit: 1) {
          edges {
            node {
              items {
                id
                image {
                  gatsbyImageData(
                    width: 512
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
                title
                itemScore
                levelRequired
                type
                stats {
                  id
                  type
                  value
                }
                attributes {
                  raw
                }
                anointed {
                  raw
                }
                price
                brand
                link
              }
            }
          }
        }
      }
    `
  )

  if (typeof items === "undefined")
    items = query.allContentfulWeek.edges[0].node.items

  return (
    <div className="item-row">
      {items &&
        items.map((item: any) => {
          return (
            <div key={item.id} className="item">
              <ItemCard data={item} />
            </div>
          )
        })}
    </div>
  )
}

export default ItemRow
