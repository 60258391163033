/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

import FaviconICO from "../images/favicon-32.ico"

type Entry = {
  name: string
  content: string
}

type DataProps = {
  description?: string
  lang?: string
  meta?: Array<Entry>
  title?: string
}

const SEO: React.FC<DataProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
}) => {
  /**
   * @param query.allContentfulSeo          Contentful SEO object.
   * @param query.favicon.publicURL         SVG favicon URL.
   * @param query.safariFavicon.publicURL   Safari SVG favicon URL.
   * @param SEOData.twitter                 Author twitter handle.
   */
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulSeo(limit: 1) {
          edges {
            node {
              description
              title
              twitter
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  const SEOData = query.allContentfulSeo.edges[0].node
  const metaDescription = description || SEOData.description

  const defaultTitle = SEOData.title
  const titleTemp = title ? `%s | ${defaultTitle}` : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : defaultTitle}
      titleTemplate={titleTemp}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: "https:" + SEOData.image.file.url,
        },
        {
          property: `og:title`,
          content: title ? title : defaultTitle,
        },
        {
          name: `og:image`,
          content: "https:" + SEOData.image.file.url,
        },
        {
          name: `og:image:alt`,
          content: `Maurice`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: SEOData.twitter,
        },
        {
          name: `twitter:site`,
          content: `@wheremaurice`,
        },
        {
          name: `twitter:title`,
          content: title ? title : defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: "https:" + SEOData.image.file.url,
        },
        {
          name: `application-name`,
          content: `Maurice`,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no`,
        },
      ].concat(meta)}
      link={[
        {
          key: `gatsby-plugin-manifest-icon-link`,
          rel: `icon`,
          type: `image/svg+xml`,
          href: `/favicon.svg`,
          sizes: `32x32`,
        },
        {
          rel: `mask-icon`,
          href: `/safari-favicon.svg`,
          color: `#019ef9`,
        },
        {
          key: `gatsby-plugin-manifest-icon-link`,
          rel: `icon`,
          href: FaviconICO,
          sizes: `32x32`,
        },
      ]}
    />
  )
}

export default SEO
