import React from "react"
import ReactModal from "react-modal"
import ReactTooltip from "react-tooltip"

import Close from "../../static/close.svg"
import Info from "../../static/info.svg"

// noinspection JSUnresolvedFunction
ReactModal.setAppElement("#___gatsby")

type DataProps = {
  header: any
  children: any
  isOpen: boolean
  onClose: any
  tooltip?: any
  hasBlur?: boolean
}

const Modal: React.FC<DataProps> = ({
  header,
  children,
  isOpen,
  onClose,
  tooltip,
  hasBlur = false,
}) => {
  return (
    <ReactModal
      closeTimeoutMS={600}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={header}
    >
      <div className={`modal__inner${hasBlur ? " has-blur" : ""}`}>
        <div className={`modal__header${hasBlur ? " has-blur" : ""}`}>
          <h2 className={`modal__title${tooltip ? " has-info" : ""}`}>
            {header}
          </h2>
          {tooltip && (
            <>
              <div
                className="modal__info"
                data-tip={tooltip}
                data-for="what-is-this"
              >
                <Info aria-hidden="true" />
                <span className="visible-hidden">
                  Vending machine items are random. This is a sample of this
                  week's weapons. Stats scale to your character.
                </span>
              </div>
              <ReactTooltip
                id="what-is-this"
                className="modal__info"
                place="bottom"
                type="dark"
                effect="solid"
                backgroundColor="#019ef9"
              >
                <p>
                  Vending machine items are
                  <br /> random. This is a sample of
                  <br /> this week's weapons. Stats
                  <br /> scale to your character.
                </p>
              </ReactTooltip>
            </>
          )}
          <button className="modal__close" onClick={onClose}>
            <Close aria-hidden="true" />
            <span className="visible-hidden">Close Modal</span>
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  )
}

export default Modal
