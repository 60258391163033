/* eslint-disable import/named */
import * as React from "react"
import "@szhsin/react-menu/dist/index.css"

type DataProps = {
  root?: boolean
  link?: {
    text: string
    url: string
  }
  isReset?: boolean
}

const Footer: React.FC<DataProps> = ({ root, link, isReset }) => (
  <footer>
    {root && <div className="footer__background" />}
    <div className="footer__left">
      {!isReset && link ? (
        <>
          Thanks to{" "}
          <a target="_blank" rel="noopener noreferrer" href={link.url}>
            {link.text}
          </a>{" "}
          for finding the vending machine!
        </>
      ) : (
        <>
          Help us find the vending machine! Join our{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/82Rbh4K5w6"
          >
            Discord
          </a>
          !
        </>
      )}
    </div>
    <div className="footer__right">© 2022 Ari Birnbaum (Ceiphr).</div>
  </footer>
)

export default Footer
